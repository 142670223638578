<template>
  <Toast position="top-right" />
  <form @submit="userLogin" class="w-full h-screen bg-beige">
    <div class="surface-card p-4 shadow-2 border-round w-full mx-auto lg:w-6">
      <div class="text-center mb-5">
        <img
          src="../assets/Leica_Classic_Store_RGB_878px_0920.png"
          alt="Image"
          class="mb-3"
          style="height: 100px"
        >
        <div class="text-900 text-3xl font-medium mb-3">
          Leica Marketplace Dealer Backend
        </div>
        <span class="text-600 font-medium line-height-3">Don't have an account?</span>
        <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Write us!</a>
      </div>

      <div class="grid">
        <div class="col-6 xs:col-12">
        <label
          for="username"
          class="block text-900 font-medium mb-2"
        >Email</label>
        <InputText
          id="username"
          v-model="username"
          type="text"
          class="w-full mb-3"
        />
        </div>
        <div class="col-6 xs:col-12">
        <label
          for="password"
          class="block text-900 font-medium mb-2"
        >Password</label>
        <InputText
          id="password"
          v-model="password"
          type="password"
          class="w-full mb-3"
        />
      </div>
        <!--
        <div class="flex align-items-center justify-content-between mb-6">
          <div class="flex align-items-center">
            <Checkbox id="rememberme1" :binary="true" v-model="checked" class="mr-2"></Checkbox>
            <label for="rememberme1">Remember me</label>
          </div>
          <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
        </div>
        -->
        <div class="col-12">
        <Button
          label="Sign In"
          icon="pi pi-user"
          class="w-full"
          type="submit"
        />
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import {useAPI} from "@/composables/useAPI"
import {useRouter} from "vue-router"
import {RepositoryFactory} from "@/repositories/RepositoryFactory"
import { useToast } from "primevue/usetoast";

const username = ref('')
const password = ref('')
const userRepository = RepositoryFactory.get('users')
const toast = useToast()

// eslint-disable-next-line no-unused-vars
const {login, currentUser, saveUser} = useAPI()
// eslint-disable-next-line no-unused-vars
const router = useRouter()

const userLogin = async (ev) => {
  ev.preventDefault()

  let res = await login(username.value, password.value)
  if (!res) {
    toast.add({severity: 'error', summary: 'Error', detail:'Login failed. Please make sure credentials are correct.',  life: 3000})
    return
  }
  // eslint-disable-next-line no-unused-vars
  const me = await userRepository.getMe()
  currentUser.value.acl_roles = me.data.acl_roles
  if (me.data.leicaclassic_user_extension && me.data.leicaclassic_user_extension[0].attributes.clearanceAllowed) {
    currentUser.value.clearanceAllowed = true
  } else {
    currentUser.value.clearanceAllowed = false
  }
  saveUser()
  username.value = ''
  password.value = ''
  await router.push({path: '/main/home'})
}

</script>

<style scoped>

</style>
