import {ref} from "vue"
import router from "@/router";

const isLoading = ref(false)
const additionalHeaders = ref({})
const authenticated = ref(false);
const currentUser = ref({});

const get = async (endpoint, parameters, showLoader = true, accept = "default") => {

    if (showLoader) {
      if (isLoading.value === false) {
          isLoading.value = true
      }
    }


    let headers = {'Content-Type': 'application/json' }

    if (accept === "simple") {
        headers = {'Content-Type': 'application/json', 'Accept': 'application/json'}
    }

    Object.assign(headers, additionalHeaders.value)

    let response = await fetch(process.env.VUE_APP_API_BASEURL+endpoint+'?'+parameters.toString(), {
        headers:  headers,
    })

    if (response.status === 401) {
        await refreshToken()
        Object.assign(headers, additionalHeaders.value)
        response = await fetch(process.env.VUE_APP_API_BASEURL+endpoint+'?'+parameters.toString(), {
            headers:  headers,
        })
    }

    const json = await response.json()
    isLoading.value = false

    return json
}

const post = async (endpoint, parameters, body, showLoader = true, accept = "default") => {
    if (showLoader) {
        if (isLoading.value === false) {
            isLoading.value = true
        }
    }

    let headers = {'Content-Type': 'application/json'}

    if (accept === "simple") {
        headers = {'Content-Type': 'application/json', 'Accept': 'application/json'}
    }

    Object.assign(headers, additionalHeaders.value)

    let response = await fetch(process.env.VUE_APP_API_BASEURL+endpoint+"?"+parameters.toString(), {
        method: 'POST',
        headers:  headers,
        body: JSON.stringify(body)
    });

    if (response.status === 401) {
        await refreshToken()
        Object.assign(headers, additionalHeaders.value)
        response = await fetch(process.env.VUE_APP_API_BASEURL+endpoint+"?"+parameters.toString(), {
            method: 'POST',
            headers:  headers,
            body: JSON.stringify(body)
        });
    }

    const json = await response.json();

    isLoading.value = false;
    return json;
}

const del = async(endpoint, parameters, showLoader = true) => {
    if (showLoader) {
        if (isLoading.value === false) {
            isLoading.value = true
        }
    }

    const headers = {'Content-Type': 'application/json'}
    Object.assign(headers, additionalHeaders.value)

    let response = await fetch(process.env.VUE_APP_API_BASEURL+endpoint+'?'+parameters.toString(), {
        method: 'DELETE',
        headers:  headers,
    })

    if (response.status === 401) {
        await refreshToken()
        Object.assign(headers, additionalHeaders.value)
        response = await fetch(process.env.VUE_APP_API_BASEURL+endpoint+'?'+parameters.toString(), {
            headers:  headers,
        })
    }

    const json = await response.json()
    isLoading.value = false

    return json
}

const postFile = async (endpoint, parameters, body, showLoader = true) => {
    if (showLoader) {
        if (isLoading.value === false) {
            isLoading.value = true
        }
    }

    const headers = {'Content-Type': 'image/jpg'}
    Object.assign(headers, additionalHeaders.value)

    const response = await fetch(process.env.VUE_APP_API_BASEURL+endpoint+"?"+parameters.toString(), {
        method: 'POST',
        headers:  headers,
        body: body
    });

    if (response.status === 401) {
        await refreshToken()
    }

    if (response.status === 200) {
        const json = await response.json();

        isLoading.value = false;
        return json

    } else {
        isLoading.value = false;
        return {}
    }
}

const patch = async (endpoint, parameters, body, showLoader = true) => {
    if (showLoader) {
        if (isLoading.value === false) {
            isLoading.value = true
        }
    }

    const headers = {'Content-Type': 'application/json'}
    Object.assign(headers, additionalHeaders.value)

    const response = await fetch(process.env.VUE_APP_API_BASEURL+endpoint+"?"+parameters.toString(), {
        method: 'PATCH',
        headers:  headers,
        body: JSON.stringify(body)
    });

    if (response.status === 401) {
        await refreshToken()
    }

    if (response.status === 200) {
        const json = await response.json();

        isLoading.value = false;
        return json

    } else {
        isLoading.value = false;
        return {}
    }

}

const callUrl = async(url) => {
    const headers = {'Content-Type': 'application/json' }
    Object.assign(headers, additionalHeaders.value)

    let response = await fetch(url, {
        headers:  headers,
    })

    if (response.status === 401) {
        await refreshToken()
        Object.assign(headers, additionalHeaders.value)
        response = await fetch(url, {
            headers:  headers,
        })
    }

    const json = await response.json();

    return json
}

const callUrlRawResponse = async(url) => {
    const headers = {'Content-Type': 'application/json' }
    Object.assign(headers, additionalHeaders.value)

    let response = await fetch(url, {
        headers:  headers,
    })

    if (response.status === 401) {
        await refreshToken()
        Object.assign(headers, additionalHeaders.value)
        response = await fetch(url, {
            headers:  headers,
        })
    }

    return response
}

const login = async function(username, password) {

    const body = {
        "client_id": "administration",
        "grant_type": "password",
        "scopes": "write",
        "username": username,
        "password": password
    };
    const response = await post("/oauth/token", new URLSearchParams({}), body);

    if (response.errors) {
        // show some error message to the user
        return false
    }

    currentUser.value.username = username
    currentUser.value.access_token = response.access_token;
    currentUser.value.refresh_token = response.refresh_token;

    saveUser()

    additionalHeaders.value = {'Authorization': 'Bearer ' + response.access_token}

    // Gruppe für diesen User laden
    authenticated.value = true
    return true
}

const logout = async function() {
    window.localStorage.removeItem(process.env.LOCAL_USERDATA_KEY)
    authenticated.value = false
    additionalHeaders.value = {}
    router.push('/')
}

const refreshToken = async function() {
    const body = {
        "grant_type": "refresh_token",
        "client_id": "administration",
        "refresh_token": currentUser.value.refresh_token
    }
    const response = await post("/oauth/token", new URLSearchParams({}), body);

    if (!response.access_token) {
        // show some error message to the user
        authenticated.value = false
        currentUser.value = {}
        window.localStorage.removeItem(process.env.LOCAL_USERDATA_KEY)
        router.push('/')
    }

    currentUser.value.access_token = response.access_token;
    currentUser.value.refresh_token = response.refresh_token;
    additionalHeaders.value = {'Authorization': 'Bearer ' + currentUser.value.access_token}
    saveUser()
}

const loadUser = function() {
    currentUser.value = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_LOCAL_USERDATA_KEY) || '{}' )

    if (currentUser.value.access_token) {
        authenticated.value = true
        additionalHeaders.value = {'Authorization': 'Bearer ' + currentUser.value.access_token}
    }
}

const saveUser = function() {
    window.localStorage.setItem(process.env.VUE_APP_LOCAL_USERDATA_KEY, JSON.stringify(currentUser.value))
}

export function useAPI() {
    return {
        get,
        post,
        del,
        patch,
        postFile,
        isLoading,
        additionalHeaders,
        login,
        logout,
        loadUser,
        saveUser,
        refreshToken,
        currentUser,
        callUrl,
        callUrlRawResponse
    }
}
