import { createRouter, createWebHistory } from 'vue-router'
import {useAPI} from "@/composables/useAPI";

const {authenticated} = useAPI()
// 1. Define route components.
import LoginComponent from "@/components/LoginComponent";

// Guard Routes without Login //TODO
function guardRoute(to, from ,next) {

    if (to.path !== '/') {
        if (authenticated.value) {
            next();
        } else {
            next('/')
        }
    }
}

// 2. Define some routes
// Each route should map to a component.
const routes = [
    { path: '/',
      name: 'Login',
      component: LoginComponent
    },
    {
        path: '/main',
        name: 'Layout',
        component: () => import ('@/components/MainLayout.vue'),
        children: [
            {
                path: 'home',
                name: 'Home',
                //beforeEnter: guardRoute,
                component: () => import('@/components/HomeComponent')
            },
            {
                path: 'productlist',
                name: 'Product List',
                component: () => import('@/components/ProductListComponent')
            },
            {
                path: 'productdetail/:productid',
                name: 'Product Detail',
                component: () => import('@/components/ProductDetailComponent')
            },
            {
                path: 'orderlist',
                name: 'Order List',
                component: () => import('@/components/OrderListComponent')
            },
            {
                path: 'stats',
                name: 'Statistics',
                beforeEnter: guardRoute,
                component: () => import('@/components/StatsComponent')
            },
            {
                path: 'productListDT',
                name: 'Product List DT',
                component: () => import('@/components/ProductListDTComponent.vue')
            }
        ]

    },
];

// 3. Create the router instance and pass the `routes` option
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

export default router
