import {useAPI} from "@/composables/useAPI";

const {post, get, patch, callUrl, currentUser, isLoading} = useAPI()

export default {
    async getStateMachineStateById(id) {

        const params = new URLSearchParams({})
        return await get('/state-machine-state/'+id, params, false, "simple")

    },

    async getStateMachineTransitionForOrder(id) {

    },

    async getStateMachineTransitionForState(id, type) {
        const params = new URLSearchParams({})
        switch (type) {
            case "transaction":
                return await get('/_action/state-machine/order_transaction/'+id+'/state', params, false, 'simple')
            case "delivery":
                return await get('/_action/state-machine/order_delivery/'+id+'/state', params, false, 'simple')
            case "order":
                return await get('/_action/state-machine/order/'+id+'/state', params, false, 'simple')
        }

    },

    async getStateMachineTransitionForDelivery(id) {

    }

}
